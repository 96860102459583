import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import contentTypes, { TEXT_TYPE } from './ContentTypes';
import styles from './BlogForm.module.css';
import 'react-quill/dist/quill.snow.css';

const BlogContent = (props) => {
  const { 
    initialValues, 
    onChange,
    id,
  } = props;

  const [type, setType] = useState(initialValues?.type || TEXT_TYPE);
  const [value, setValue] = useState(initialValues?.value || '');

  useEffect(() => {
    const contentData = {
      type,
      value,
    };

    onChange(contentData);
  }, [type, value]);

  const onChangeType = (event) => {
    setType(event.target.value);
    setValue('');
  };

  // console.log('===TYPE', type, contentTypes[type]);

  return (
    <div>
      <label className={styles.row}>
        <span>Content type</span>
        <select 
          className={styles.select}
          onChange={onChangeType}
          value={type}
        >
          {_.map(contentTypes, (typeData, type) => (
            <option key={type} value={type}>
              {typeData.label}
            </option>
          ))}
        </select>
      </label>
      <span>Content</span>
      {contentTypes[type].editor({ onChange: setValue, value, id })}
    </div>
  );
};

export default BlogContent;
